import React, { useState } from "react";
import { ENDPOINT } from "constants/endpoints";
// components

import CardTrackNewsForm from "components/Cards/CardTrackNewsForm.js";

export default function NewTrack() {
  const [loading, setLoading] = useState(false);
  const [keyword, setkeyword] = useState("");

  const handleChangeKeyword = (e) => {
    setkeyword(e.target.value);
  };

  const onValidate = () => {
    if (keyword === "") {
      alert("Masukkan kata kunci");
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (onValidate() && !loading) {
      setLoading(true);
      const payload = {
        data: {
          query: keyword,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      const endpoint = `${ENDPOINT.BACKEND}/news-issue/new`;
      let res = await fetch(endpoint, requestOptions);
      res = await res.json();
      console.log(endpoint);
      console.log(payload);
      console.log("res", res);
      window.location.href = "/admin/news-issue";
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <CardTrackNewsForm
            onChangeKeyword={handleChangeKeyword}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </>
  );
}
