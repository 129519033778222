import React, { useEffect, useState } from "react";
import { ENDPOINT } from "constants/endpoints";
import { checkLogin } from "helper/session";
// components

import CardTrackList from "components/Cards/CardTrackList.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function Track() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOps, setSelectedOps] = useState(0);

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchIssueList();
  }, []);

  const handleOpenModal = (id) => {
    if (id === selectedOps) {
      setSelectedOps(0);
    } else {
      setSelectedOps(id);
    }
  };

  const showConfirmation = (id) => {
    confirmAlert({
      //title: 'Konfrimasi Pengahapusan Data',
      message: "Kamu yakin ingin menghapus data ini?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            deleteTrack(id);
          },
        },
        {
          label: "Tidak",
          onClick: () => {
            setSelectedOps(0);
          },
        },
      ],
    });
  };

  const onPressUpdate = (id) => {
    setSelectedOps(0);
    window.location.href = "/admin/update-track?id=" + id;
  };

  const updateTrack = async (id, status) => {
    setLoading(true);
    setSelectedOps(0);
    const payload = {
      data: {
        status: status === "active" ? "stop" : "active",
      },
      id: id,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    const endpoint = `${ENDPOINT.BACKEND}/tracking/update`;
    let res = await fetch(endpoint, requestOptions);
    res = await res.json();
    console.log("update", res);

    fetchIssueList();
    setLoading(false);
  };

  const deleteTrack = async (id) => {
    setLoading(true);
    setSelectedOps(0);
    const payload = {
      data: {
        status: "deleted",
      },
      id: id,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    const endpoint = `${ENDPOINT.BACKEND}/tracking/update`;
    let res = await fetch(endpoint, requestOptions);
    res = await res.json();
    console.log("dele", res);

    fetchIssueList();
    setLoading(false);
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  const fetchIssueList = async () => {
    setLoading(true);
    const endpoint = `${ENDPOINT.BACKEND}/issue/list`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();

    console.log(res);

    if (res?.status) {
      setList(res?.data);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-wrap">
      {loading ? (
        _renderLoading()
      ) : (
        <CardTrackList
          data={list}
          selectedOps={selectedOps}
          handlePause={updateTrack}
          handleDelete={showConfirmation}
          handleUpdate={onPressUpdate}
          handleOpenModal={handleOpenModal}
        />
      )}
    </div>
  );
}
