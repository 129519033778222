import React, { useEffect, useState } from "react";

// components
import { ENDPOINT } from "constants/endpoints";
import moment from "moment";
import PostList from "components/Cards/PostList.js";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { checkLogin } from "helper/session";

//import Datepicker from "react-tailwindcss-datepicker";

export default function HistoryTopic() {
  const [loading, setLoading] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [textSummary, setTextSummary] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [issueText, setIssueText] = useState("");
  const [postList, setPostList] = useState([]);
  const [showPostList, setShowPostList] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showNext, setShowNext] = useState(true);
  const perPage = 10;
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const startDate = queryParameters.get("start");
  const endDate = queryParameters.get("end");
  const id = queryParameters.get("id");
  const topic = queryParameters.get("topic");
  const label = queryParameters.get("label");

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await fetchPost(id);
    await fetchIssue(id);
    setLoading(false);
  };

  const fetchIssue = async (id = 0) => {
    setLoading(true);
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const issueId = id === 0 ? selectedId : id;

    let senti = "";
    if (label) {
      senti = "&label=" + label;
    }

    const endpoint = `${ENDPOINT.BACKEND}/issue/${issueId}?start=${start}&end=${end}${senti}`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();
    console.log("endpoint", res);
    setIssueText(res?.data?.title);

    let list = [];
    let pos = [];
    let neg = [];
    let neu = [];
    res.data.list.map((e) => {
      if (start === end) {
        list.push(`${e.hour}:00 WIB`);
      } else {
        list.push(`${e.date}`);
      }
      pos.push(e.total_positive);
      neg.push(e.total_negative);
      neu.push(e.total_neutral);
    });

    setLoading(false);
  };

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const getSummary = async (text) => {
    setLoadingSummary(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query: text }),
    };

    const endpoint = `${ENDPOINT.BACKEND}/summary`;
    let res = await fetch(endpoint, requestOptions);
    res = await res.json();

    console.log("berforeText", text);
    console.log("getSummary", res);

    if (res.status) {
      setTextSummary(res.data);
    }
    setLoadingSummary(false);
  };

  const fetchPost = async (id = 0) => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    let senti = "";
    if (label) {
      senti = "&label=" + label;
    }
    const issueId = id === 0 ? selectedId : id;
    const endpoint = `${ENDPOINT.BACKEND}/issue/latest-by-id/?id=${issueId}&start=${start}&end=${end}&topic=${topic}${senti}`;
    let res = await fetch(endpoint, {
      method: "GET",
    });

    res = await res.json();
    console.log("post ", res);

    if (res?.data) {
      setIssueText(res?.data?.query);
    }

    let list = [];
    let allText = "";
    await res.data.map((e) => {
      const item = {
        date: e.attributes.posted_at,
        text: e.attributes.raw_text,
        sentiment: e.attributes.label,
      };
      list.push(item);
      allText = `${allText}. ${e.attributes.cleaned_text}`;
    });

    getSummary(allText);

    await setPostList(list);
    await setPage(1);

    const l = paginate(list, 10, 1);
    setShowPostList(l);
    console.log("list.length ", list.length);

    if (res.data.length < 1) {
      setShowNext(false);
    } else {
      const maxPage = res.data.length / perPage;
      console.log("MAX", maxPage);
      setMaxPage(maxPage);
      setShowNext(true);
    }
  };

  const onPressNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    const l = paginate(postList, 10, nextPage);
    const newList = showPostList.concat(l);
    setShowPostList(newList);

    console.log("nextPage", nextPage);
    console.log("newList", newList);

    if (nextPage >= maxPage) {
      setShowNext(false);
    }
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  const _renderPlaceholder = () => {
    return (
      <div role="status" className="max-w-sm animate-pulse mt-2">
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
      </div>
    );
  };

  const _renderSummary = () => {
    if (loadingSummary) return _renderPlaceholder();

    let predic = "";
    if (label === "positive") {
      predic = "Prediksi isu ini akan menjadi negatif 2-5 hari kedepan";
    }
    if (label === "negative") {
      predic =
        "Prediksi isu ini akan berkurang dengan cara mengalihkan isu oleh buzzer";
    }

    return (
      <>
        <p>
          Ringkasan: <i>" {textSummary} "</i>
        </p>
        <br />
        <p>
          <b>{predic}</b>
        </p>
      </>
    );
  };

  const _renderIcon = () => {
    if (label === "negative") {
      return (
        <span>
          <i className="fas fa-arrow-down text-red-500 mr-4"></i>Negatif
        </span>
      );
    } else if (label === "positive") {
      return (
        <span>
          <i className="fas fa-arrow-up text-emerald-500 mr-4"></i> Positif
        </span>
      );
    } else {
      return (
        <span>
          <i className="fas fa-dot-circle text-gray-400 mr-4"></i> Netral
        </span>
      );
    }
  };

  const _renderTitle = () => {
    if (loading) {
      return _renderLoading();
    }

    const startText = moment(startDate).format("DD MMMM YYYY");
    const endText = moment(endDate).format("DD MMMM YYYY");
    const tgl =
      startText === endText ? startText : `${startText} sampai ${endText}`;

    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 		">
          <p className="text-2xl	font-bold ">{issueText}</p>
          {_renderIcon()}
          <br />
          <p>{_renderSummary()}</p>
          <br />
          <p className="text-m	 ">Topik: {topic}</p>
          <p className="text-xs ">{tgl}</p>
        </div>
      </>
    );
  };
  const showList = () => {
    if (loading) return null;

    return (
      <div className="justify-self-center">
        <PostList data={showPostList} />
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {_renderTitle()}
        </div>
        <div className="w-full xl:w-4/12 px-4"></div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4 items-center justify-center">
          {showList()}
          {showNext && !loading && (
            <button
              onClick={onPressNextPage}
              className="mt-5 bg-lightBlue-500 text-white  font-bold px-3 py-1.5  rounded outline-none focus:outline-none mr-1 mb-1  duration-150 justify-self-center"
              type="button"
            >
              Selanjutnya
            </button>
          )}
        </div>
      </div>
    </>
  );
}
