import React from "react";
import moment from "moment";

// components



export default function PostList(props) {
    const {data = [] } = props;


    const _renderSentimentIcon = (status) => {
        let icon = <span><i className="fas fa-dot-circle text-gray-400 mr-4" ></i> Netral</span>;

        if(status === 'positive'){
            icon = <span><i className="fas fa-arrow-up text-emerald-500 mr-4" ></i> Positif</span>;
        }

        if(status === 'negative'){
            icon = <span><i className="fas fa-arrow-down text-red-500 mr-4" ></i> Negatif</span>;
        }

        return icon;
    }

    const _rendetItem = () => {
        if(data.length > 0){
            return data.map(item => {
                const date = moment(item.date).format('LLL'); 

                return (
                    <tr>
                        <td className=" w-xs border-t-0  border-l-0 border-r-0 text-xs  p-4 text-left">
                        {date}
                        </td>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-left max-w-xs whitespace-normal">
                            {item.text}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center" title="netral">
                            {_renderSentimentIcon(item.sentiment)}
                        </td>
                    </tr>
                )
            })
        }
    }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Tangggal
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Post
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Sentimen
                </th>
              </tr>
            </thead>
            <tbody>
              {_rendetItem()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
