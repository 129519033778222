import React, { useState } from "react";
import { ENDPOINT } from "constants/endpoints";
// components

import CardTrackForm from "components/Cards/CardTrackForm.js";

export default function NewTrackNews() {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [keyword, setkeyword] = useState("");
  const [maxData, setMaxData] = useState(100);
  const [minAlertData, setMinAlertData] = useState(200);
  const [minAlertPercent, setMinAlertPercent] = useState(20);

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleChangeKeyword = (e) => {
    setkeyword(e.target.value);
  };
  const handleChangeMaxData = (e) => {
    setMaxData(e.target.value);
  };
  const handleChangeMinData = (e) => {
    setMinAlertData(e.target.value);
  };
  const handleChangeMinPercent = (e) => {
    setMinAlertPercent(e.target.value);
  };

  const onValidate = () => {
    if (
      title === "" ||
      keyword === "" ||
      !maxData ||
      !minAlertData ||
      !minAlertPercent
    ) {
      alert("Data harus diisi");
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (onValidate() && !loading) {
      setLoading(true);
      const payload = {
        data: {
          title: title,
          query: keyword,
          max_data: maxData,
          min_data_alert: minAlertData,
          percent_alert_number: minAlertPercent,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      const endpoint = `${ENDPOINT.BACKEND}/tracking/new`;
      let res = await fetch(endpoint, requestOptions);
      res = await res.json();
      window.location.href = "/admin/track";
      setLoading(false);
      return console.log("res", res);
    }
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <CardTrackForm
            onChangeTitle={handleChangeTitle}
            onChangeKeyword={handleChangeKeyword}
            onChangeMaxData={handleChangeMaxData}
            onChangeMinData={handleChangeMinData}
            onChangeMinPercent={handleChangeMinPercent}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </>
  );
}
