import React, { useEffect } from "react";
import { ReactSession } from 'react-client-session';
import {logout} from "helper/session";

export default function Login() {

  useEffect(() => {
    logout()
  }, []);
  
  return null
}

