import React, { useEffect, useState } from "react";

// components
import CardPieChart from "components/Cards/CardPieChart.js";
import CardBarChart from "components/Cards/CardBarChart.js";
import PostList from "components/Cards/PostList.js";
import { checkLogin } from "helper/session";
import { ENDPOINT } from "constants/endpoints";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [barLoading, setbarLoading] = useState(false);
  const [list, setList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [page, setPage] = useState(1);
  const [showPostList, setShowPostList] = useState([]);
  const [showNext, setShowNext] = useState(true);
  const [maxPage, setMaxPage] = useState(1);
  const perPage = 10;
  const [data, setData] = useState([0, 0, 0]);
  const [positive, setPositive] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [negative, setNegative] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [neutral, setNeutral] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [labels, setLabel] = useState(["", "", "", "", "", "", ""]);

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchIssue();
    fetchIssueStatistic();
  }, []);

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const fetchIssue = async () => {
    setLoading(true);

    const endpoint = `${ENDPOINT.BACKEND}/issue/latest`;

    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();

    let list = [];
    res.data.map((e) => {
      const item = {
        date: e.attributes.posted_at,
        text: e.attributes.raw_text,
        sentiment: e.attributes.label,
      };
      list.push(item);
    });

    setList(list);
    await setPage(1);
    const l = paginate(list, 10, 1);
    setShowPostList(l);

    if (res.data.length <= 1) {
      setShowNext(false);
    } else {
      const maxPage = res.data.length / perPage;
      console.log("MAX", maxPage);
      setMaxPage(maxPage);
      setShowNext(true);
    }

    console.log("LOG", res.data);
    setLoading(false);
  };

  const fetchIssueStatistic = async () => {
    setbarLoading(true);
    const endpoint = `${ENDPOINT.BACKEND}/issue/last-7`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();

    if (res.status) {
      const d = [res?.data?.positive, res?.data?.negative, res?.data?.neutral];
      setData(d);

      let rawPositive = [];
      let rawNegative = [];
      let rawNeutral = [];
      let rawLabel = [];
      res?.data?.list.map((e) => {
        rawPositive.push(e.total_positive);
        rawNegative.push(e.total_negative);
        rawNeutral.push(e.total_neutral);
        rawLabel.push(e.date);
      });
      setPositive(rawPositive);
      setNegative(rawNegative);
      setNeutral(rawNeutral);
      setLabel(rawLabel);
    }

    console.log("Statistic", res);
    setbarLoading(false);
  };

  const onPressNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    const l = paginate(list, 10, nextPage);
    const newList = showPostList.concat(l);
    setShowPostList(newList);

    if (nextPage >= maxPage) {
      setShowNext(false);
    }
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {barLoading ? (
            _renderLoading()
          ) : (
            <CardBarChart
              title="Sentimen seminggu terakhir"
              positive={positive}
              negative={negative}
              neutral={neutral}
              labels={labels}
            />
          )}
        </div>
        <div className="w-full xl:w-4/12 px-4">
          {barLoading ? (
            _renderLoading()
          ) : (
            <CardPieChart data={data} title="Sentimen seminggu terakhir" />
          )}
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {loading ? _renderLoading() : <PostList data={showPostList} />}
          {showNext && !loading && (
            <button
              onClick={onPressNextPage}
              className="mt-5 bg-lightBlue-500 text-white  font-bold px-3 py-1.5  rounded outline-none focus:outline-none mr-1 mb-1  duration-150 justify-self-center"
              type="button"
            >
              Selanjutnya
            </button>
          )}
        </div>
        <div className="w-full xl:w-4/12 px-4"></div>
      </div>
    </>
  );
}
