import React, { useState } from "react";
import { ENDPOINT } from "constants/endpoints";
// components
import CardWebsiteForm from "components/Cards/CardWebsiteForm.js";

export default function NewWebsite() {
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState("");
  const [name, setName] = useState("");

  //
  const [linkTag, setLinkTag] = useState("");
  const [linkId, setLinkId] = useState("");
  const [linkClass, setLinkClass] = useState("");
  const [childLinkClass, setChildLinkClass] = useState("");
  const [childLinkTag, setChildLinkTag] = useState("");
  const [childLinkId, setChildLinkId] = useState("");

  const [titleTag, setTitleTag] = useState("");
  const [titleId, setTitleId] = useState("");
  const [titleClass, setTitleClass] = useState("");
  const [childTitleClass, setChildTitleClass] = useState("");
  const [childTitleId, setChildTitleId] = useState("");
  const [childTitleTag, setChildTitleTag] = useState("");

  const [contentTag, setContentTag] = useState("");
  const [contentId, setContentId] = useState("");
  const [contentClass, setContentClass] = useState("");
  const [childContentClass, setChildContentClass] = useState("");
  const [childContentId, setChildContentId] = useState("");
  const [childContentTag, setChildContentTag] = useState("");

  const [canSave, setCanSave] = useState(false);

  const [resultTitle, setResultTitle] = useState("");
  const [resultContent, setResultContent] = useState("");

  const handleChangeDomain = (e) => {
    setCanSave(false);
    setDomain(e.target.value);
  };
  const handleChangeName = (e) => {
    setCanSave(false);
    setName(e.target.value);
  };
  const handleChangeLinkTag = (e) => {
    setCanSave(false);
    setLinkTag(e.target.value);
  };
  const handleChangeLinkId = (e) => {
    setCanSave(false);
    setLinkId(e.target.value);
  };
  const handleChangeLinkClass = (e) => {
    setCanSave(false);
    setLinkClass(e.target.value);
  };
  const handleChangeTitleTag = (e) => {
    setCanSave(false);
    setTitleTag(e.target.value);
  };
  const handleChangeTitleId = (e) => {
    setCanSave(false);
    setTitleId(e.target.value);
  };
  const handleChangeTitleClass = (e) => {
    setCanSave(false);
    setTitleClass(e.target.value);
  };
  const handleChangeContentTag = (e) => {
    setCanSave(false);
    setContentTag(e.target.value);
  };
  const handleChangeContentId = (e) => {
    setCanSave(false);
    setContentId(e.target.value);
  };
  const handleChangeContentClass = (e) => {
    setCanSave(false);
    setContentClass(e.target.value);
  };
  const handleChangeChildTitleTag = (e) => {
    setCanSave(false);
    setChildTitleTag(e.target.value);
  };
  const handleChangeChildTitleClass = (e) => {
    setCanSave(false);
    setChildTitleClass(e.target.value);
  };
  const handleChangeChildTitleId = (e) => {
    setCanSave(false);
    setChildTitleId(e.target.value);
  };
  const handleChangeChildLinkTag = (e) => {
    setCanSave(false);
    setChildLinkTag(e.target.value);
  };
  const handleChangeChildLinkId = (e) => {
    setCanSave(false);
    setChildLinkId(e.target.value);
  };
  const handleChangeChildLinkClass = (e) => {
    setCanSave(false);
    setChildLinkClass(e.target.value);
  };
  const handleChangeChildContentId = (e) => {
    setCanSave(false);
    setChildContentId(e.target.value);
  };
  const handleChangeChildContentClass = (e) => {
    setCanSave(false);
    setChildContentClass(e.target.value);
  };
  const handleChangeChildContentTag = (e) => {
    setCanSave(false);
    setChildContentTag(e.target.value);
  };

  const onValidate = () => {
    if (domain === "" || name === "") {
      alert("Domain / Nama website harus diisi");
      return false;
    }
    return true;
  };

  const onSubmitCheck = async () => {
    setLoading(true);
    let payload = {
      data: {
        domain: domain,
        linkTag: linkTag,
        linkId: linkId,
        linkClass: linkClass,
        linkChild: {},
        title: {
          id: titleId,
          tag: titleTag,
          class: titleClass,
          child: {},
        },
        content: {
          id: contentId,
          tag: contentTag,
          class: contentClass,
          child: {},
        },
      },
    };

    // Link Child
    if (childLinkTag !== "" && childLinkTag !== null) {
      payload["data"]["linkChild"]["tag"] = childLinkTag;
    }
    if (childLinkId !== "" && childLinkId !== null) {
      payload["data"]["linkChild"]["id"] = childLinkId;
    }
    if (childLinkClass !== "" && childLinkClass !== null) {
      payload["data"]["linkChild"]["class"] = childLinkClass;
    }
    // Title Child
    if (childTitleTag !== "" && childTitleTag !== null) {
      payload["data"]["title"]["child"]["tag"] = childTitleTag;
    }
    if (childTitleId !== "" && childTitleId !== null) {
      payload["data"]["title"]["child"]["id"] = childTitleId;
    }
    if (childTitleClass !== "" && childTitleClass !== null) {
      payload["data"]["title"]["child"]["class"] = childTitleClass;
    }
    // Content Child
    if (childContentTag !== "" && childContentTag !== null) {
      payload["data"]["content"]["child"]["tag"] = childContentTag;
    }
    if (childContentId !== "" && childContentId !== null) {
      payload["data"]["content"]["child"]["id"] = childContentId;
    }
    if (childContentClass !== "" && childContentClass !== null) {
      payload["data"]["content"]["child"]["class"] = childContentClass;
    }

    console.log("payload", payload);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    const endpoint = `${ENDPOINT.BACKEND}/news-url/inspect`;
    let res = await fetch(endpoint, requestOptions);
    res = await res.json();
    setLoading(false);
    if (res.status) {
      if (!res.data.isFound) {
        alert("Data berita tidak ditemukan. Periksa kembali inspeksi");
        setCanSave(false);
      } else {
        setResultTitle(res.data.title);
        setResultContent(res.data.content);
        setCanSave(true);
      }
    }
    return console.log("res", res);
  };

  const onSubmit = async () => {
    if (onValidate() && !loading) {
      setLoading(true);
      const payload = {
        data: {
          url: domain,
          name: name,
          linkTag: linkTag,
          linkId: linkId,
          linkClass: linkClass,
          titleId: titleId,
          titleTag: titleTag,
          titleClass: titleClass,
          contentId: contentId,
          contentTag: contentTag,
          contentClass: contentClass,
          //
          childLinkTag: childLinkTag,
          childLinkId: childLinkId,
          childLinkClass: childLinkClass,
          childTitleClass: childTitleClass,
          childTitleId: childTitleId,
          childTitleTag: childTitleTag,
          childContentTag: childContentTag,
          childContentId: childContentId,
          childContentClass: childContentClass,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      const endpoint = `${ENDPOINT.BACKEND}/news-url/new`;
      let res = await fetch(endpoint, requestOptions);
      res = await res.json();
      window.location.href = "/admin/news-list";
      setLoading(false);
      return console.log("res", res);
    }
  };

  const renderCheckResult = () => {
    if (!canSave) return null;
    return (
      <div>
        <div class="block  text-blueGray-600 text-xs font-bold mb-4 mt-4">
          Berita ditemukan
        </div>
        <div class="block  text-blueGray-600 text-xs font-bold mb-4 mt-4">
          Judul: <br />
          {resultTitle}
        </div>
        <div class="block text-blueGray-600 text-xs font-bold mb-4 mt-4">
          Isi Berita: <br />
          {resultContent}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <CardWebsiteForm
            onSubmit={onSubmit}
            onSubmitCheck={onSubmitCheck}
            onChangeName={handleChangeName}
            name={name}
            onChangeDomain={handleChangeDomain}
            domain={domain}
            onChangeLinkTag={handleChangeLinkTag}
            linkTag={linkTag}
            onChangeLinkId={handleChangeLinkId}
            linkId={linkId}
            onChangeLinkClass={handleChangeLinkClass}
            linkClass={linkClass}
            onChangeTitleTag={handleChangeTitleTag}
            titleTag={titleTag}
            onChangeTitleId={handleChangeTitleId}
            titleId={titleId}
            onChangeTitleClass={handleChangeTitleClass}
            titleClass={titleClass}
            onChangeContentTag={handleChangeContentTag}
            contentTag={contentTag}
            onChangeContentId={handleChangeContentId}
            contentId={contentId}
            onChangeContentClass={handleChangeContentClass}
            contentClass={contentClass}
            //
            childLinkClass={childLinkClass}
            childLinkId={childLinkId}
            childLinkTag={childLinkTag}
            childTitleClass={childTitleClass}
            childTitleId={childTitleId}
            childTitleTag={childTitleTag}
            childContentId={childContentId}
            childContentTag={childContentTag}
            childContentClass={childContentClass}
            //
            onChangeChildLinkId={handleChangeChildLinkId}
            onChangeChildLinkClass={handleChangeChildLinkClass}
            onChangeChildLinkTag={handleChangeChildLinkTag}
            onChangeChildTitleTag={handleChangeChildTitleTag}
            onChangeChildTitleClass={handleChangeChildTitleClass}
            onChangeChildTitleId={handleChangeChildTitleId}
            onChangeChildContentTag={handleChangeChildContentTag}
            onChangeChildContentId={handleChangeChildContentId}
            onChangeChildContentClass={handleChangeChildContentClass}
            canSave={canSave}
            loading={loading}
          />
        </div>

        <div className="w-full lg:w-4/12 px-4 bg-white mt-20">
          <br />
          {renderCheckResult()}
        </div>
      </div>
    </>
  );
}
