import React from "react";
import { createPopper } from "@popperjs/core";

const TrackingDropdown = (props) => {
  const {
    handleUpdate = (e) => {}, 
    handleDelete,
    handlePause, 
    id,
    status = 'active',
    selectedOption,
    handleOpen
   } = props;

  // dropdown props

  const isSelected = id === selectedOption;



  const onPressUpdate = () => {
    handleUpdate(id)
  }

  const onPressDelete = () => {
    handleDelete(id)
  }

  const onPressPause = () => {
    handlePause(id, status)
  }

  return (
    <>
      <a
        className="text-blueGray-500 py-1 px-3"
        href="#"
        onClick={handleOpen}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        className={
          (isSelected ? "block "  : "hidden " ) +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
        href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={onPressUpdate}
        >
          Ubah
        </a>
        <a
        href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={onPressPause}
        >
          {status !== 'active' ? 'Lanjutkan' : 'Berhenti'}
        </a>
        <a
        href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={onPressDelete}
        >
          Hapus
        </a>
       
      </div>
    </>
  );
};

export default TrackingDropdown;
