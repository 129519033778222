import React from "react";
// components

export default function TopNewsList(props) {
  const { data = [] } = props;

  const _rendetItem = () => {
    if (data.length > 0) {
      return data.map((i) => {
        return (
          <tr>
            <td className=" w-xs border-t-0  border-l-0 border-r-0 text-xs  p-4 text-left">
              <a href={i.url} target="_BLANK">
                {i.name}
              </a>
            </td>

            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-left max-w-xs whitespace-normal">
              {i.total}
            </th>
          </tr>
        );
      });
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Nama Media
                </th>

                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Total Berita
                </th>
              </tr>
            </thead>
            <tbody>{_rendetItem()}</tbody>
          </table>
        </div>
      </div>
    </>
  );
}
