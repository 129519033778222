import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// components
import GeneralDropdown from "components/Dropdowns/GeneralDropdown.js";

export default function CardNewsList({
  data,
  handlePause,
  selectedOps,
  handleOpenModal,
  handleUpdate,
  handleDelete,
}) {
  const renderData = () => {
    return data.map((e) => {
      return (
        <tr>
          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
            <span className={"ml-3 font-bold text-blueGray-600"}>
              {e?.attributes?.name}
            </span>
          </th>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {e?.attributes?.url}
          </td>

          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
            <GeneralDropdown
              handlePause={handlePause}
              id={e?.id}
              status={e?.attributes?.status}
              selectedOption={selectedOps}
              handleOpen={() => {
                handleOpenModal(e?.id);
              }}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          "bg-white"
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className={"font-semibold text-lg " + "text-blueGray-700"}>
                Daftar Website Berita Yang Dilacak
              </h3>
            </div>
            <Link to="/admin/new-website">
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                formAction=""
              >
                Tambah Website Baru
              </button>
            </Link>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  Website
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                >
                  Domain
                </th>

                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                ></th>
              </tr>
            </thead>
            <tbody>{renderData()}</tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardNewsList.defaultProps = {
  color: "light",
  data: ["", ""],
};

CardNewsList.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  data: PropTypes.array,
};
