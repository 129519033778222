import React, { useEffect, useState } from "react";
import { ENDPOINT } from "constants/endpoints";
import { checkLogin } from "helper/session";

// components

import CardBlacklist from "components/Cards/CardBlacklist.js";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function Blaclist() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOps, setSelectedOps] = useState(0);

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchIssueList();
  }, []);

  const handleUpdate = (id) => {
    setSelectedOps(0);
    window.location.href = "/admin/update-blacklist?id=" + id;
  };

  const showConfirmation = (id) => {
    confirmAlert({
      //title: 'Konfrimasi Pengahapusan Data',
      message: "Kamu yakin ingin menghapus data ini?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            deleteBlacklist(id);
          },
        },
        {
          label: "Tidak",
          onClick: () => {
            setSelectedOps(0);
          },
        },
      ],
    });
  };

  const deleteBlacklist = async (id) => {
    setLoading(true);
    setSelectedOps(0);
    const payload = {
      data: {
        status: "deleted",
      },
      id: id,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    const endpoint = `${ENDPOINT.BACKEND}/blacklist/update`;
    let res = await fetch(endpoint, requestOptions);
    res = await res.json();

    fetchIssueList();
    setLoading(false);
  };

  const handleOpenModal = (id) => {
    if (id === selectedOps) {
      setSelectedOps(0);
    } else {
      setSelectedOps(id);
    }
  };

  const fetchIssueList = async () => {
    setLoading(true);
    const endpoint = `${ENDPOINT.BACKEND}/blacklist`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();
    console.log("isu", res);
    if (res?.status) {
      setList(res?.data);
    }
    setLoading(false);
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardBlacklist
            data={list}
            selectedOps={selectedOps}
            handleUpdate={handleUpdate}
            handleDelete={showConfirmation}
          />
          {loading && _renderLoading()}
        </div>
      </div>
    </>
  );
}
