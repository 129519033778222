import React, { useEffect, useState } from "react";
import { checkLogin } from "helper/session";

// components
import TopNewsList from "components/Cards/TopNewsList.js";
import { ENDPOINT } from "constants/endpoints";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function TopNews() {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startText, setStartText] = useState("");
  const [endText, setEndText] = useState("");
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchTopNews();
  }, []);

  const fetchTopNews = async (id = 0) => {
    setLoading(true);
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const s = moment(startDate).format("DD/MM/YYYY");
    const e = moment(endDate).format("DD/MM/YYYY");
    setStartText(s);
    setEndText(e);

    const endpoint = `${ENDPOINT.BACKEND}/news-url/top?start=${start}&end=${end}`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();
    console.log("URL", endpoint);
    console.log("res", res);

    if (res.status) {
      setDataList(res.data);
    }
    setLoading(false);
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  const _renderFilter = () => {
    return (
      <div date-rangepicker className="flex items-center justify-center">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            </svg>
          </div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <span className="mx-4 text-gray-500">sampai</span>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            </svg>
          </div>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>
    );
  };

  const _renderTitle = () => {
    if (loading) {
      return _renderLoading();
    }

    const tgl =
      startText === endText ? startText : `${startText} sampai ${endText}`;

    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 		">
          <p className="text-sm	font-bold ">{tgl}</p>
        </div>
      </>
    );
  };

  const _renderTop = () => {
    if (loading) {
      return null;
    }

    return (
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5">
        {_renderFilter()}
        <button
          onClick={fetchTopNews}
          className="mt-5 bg-lightBlue-500 text-white  font-bold px-3 py-1.5  rounded outline-none focus:outline-none mr-1 mb-1"
          type="button"
        >
          Tampilkan Data
        </button>
      </div>
    );
  };

  const showList = () => {
    if (loading) return null;

    return (
      <div className="justify-self-center">
        <TopNewsList data={dataList} />
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {_renderTop()}
          {_renderTitle()}
          {showList()}
        </div>
      </div>
      <div className="flex flex-wrap mt-4"></div>
    </>
  );
}
