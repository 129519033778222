import React, { useState } from "react";
import {ENDPOINT} from 'constants/endpoints'
// components
import CardBlacklistForm from "components/Cards/CardBlacklistForm.js";

export default function NewBlacklist() {

  const [loading, setLoading] = useState(false)
  const [account, setAccount] = useState('')
  const [name, setName] = useState('')


  const handleChangeAccount = (e)=> {
    setAccount(e.target.value)
  }
  const handleChangeName = (e)=> {
    setName(e.target.value)
  }

  const onValidate = () => {
    if(account === ''){
      alert("Akun harus diisi");
      return false
    }
    return true;
  }

  const onSubmit = async () => {
    if(onValidate() && !loading){
        setLoading(true)
        const payload = {
          data: {
            username: account,
            display_name: name,
          }
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const endpoint = `${ENDPOINT.BACKEND}/blacklist/new`;
        let res = await fetch(endpoint, requestOptions);
        res = await res.json()
        window.location.href = "/admin/blacklist";
        setLoading(false)
        return console.log("res",res)
    }
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <CardBlacklistForm 
            onChangeAccount={handleChangeAccount} 
            onChangeName={handleChangeName}
            onSubmit={onSubmit}
            />
        </div>
      </div>
    </>
  );
}
