import React, { useEffect, useState } from "react";
import { checkLogin } from "helper/session";
// components

import CardLineChart from "components/Cards/CardLineChart.js";
import CardBarChart from "components/Cards/CardBarChart.js";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import CardFormSearch from "components/Cards/CardFormSearch.js";
import { ENDPOINT } from "constants/endpoints";

require("dotenv").config();

export default function Sentiment() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchIssue();
  }, []);

  const fetchIssue = async () => {
    setLoading(true);
    const id = 2;
    const start = "2023-07-12";
    const end = "2023-07-12";

    const endpoint = `${ENDPOINT.BACKEND}/issue/${id}?start=${start}&end=${end}`;
    console.log("LOG", endpoint);

    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();

    console.log("LOG", res);
    setLoading(false);
  };

  const _renderDropdown = () => {
    return (
      <>
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          Dropdown button{" "}
          <svg
            className="w-2.5 h-2.5 ml-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
        >
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <a
                href="#"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Settings
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Earnings
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  };

  const _renderSearchQuery = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="block w-full overflow-x-auto p-4">
            <h3>Hasil Sentimen:</h3>
            <h4>"Jokowi"</h4>
          </div>
          {_renderDropdown()}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {_renderSearchQuery()}
          <CardBarChart />
          <CardPageVisits />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardLineChart />
        </div>
      </div>
    </>
  );
}
