import React from "react";

// components

export default function CardTopicList(props) {
    const {list, id, start, end, title, bgColor, activeColor, label = ''} = props;

    const renderList = () => {
        return list.map(e => {
          const senti = label === '' ? '' : '&label=' + label;
          const link = `/admin/topic?id=${id}&start=${start}&end=${end}&topic=${e.topic}${senti}`;
          const bgClass = `overflow-hidden h-2 text-xs flex rounded bg-purple-200 ${activeColor}`
          const barClass = `shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${bgColor}`
          
            return (
                <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  <a href={link}> {e.topic}</a>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {e.count}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <div className="flex items-center">
                    <span className="mr-2">{e.percentage}%</span>
                    <div className="relative w-full">
                      <div className={bgClass}>
                        <div
                          style={{ width: `${e.percentage}%` }}
                          className={barClass}
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )
        })
    }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                {title}
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
             
             
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="thead-light">
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Topik
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Total
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"></th>
              </tr>
            </thead>
            <tbody>
            {renderList()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
