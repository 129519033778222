import React, { useEffect, useState } from "react";
import { checkLogin } from "helper/session";
import { ENDPOINT } from "constants/endpoints";
// components
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

export default function Summary() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const query = queryParameters.get("q");

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchIssue();
  }, []);

  const fetchIssue = async () => {
    if (query && query.length > 5) {
      setLoading(true);

      const endpoint = `${ENDPOINT.BACKEND}/summary`;
      console.log("LOG", endpoint);

      let res = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: query,
        }),
      });
      res = await res.json();
      setResult(res.data);
      console.log(res);
    }

    setLoading(false);
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="justify-self-center">
            {loading ? (
              <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
            ) : (
              <p>
                <i>{result}</i>
              </p>
            )}
          </div>
        </div>
      </>
    );
  };

  const _renderForm = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">
                Cek Akurasi Kesimpulan
              </h6>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form action="/admin/summary" method="get">
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Masukkan kalimat yang akan disimpulkan
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <textarea
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      rows="4"
                      name="q"
                      defaultValue={query}
                    ></textarea>
                    <button
                      className="mt-2 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Lihat Kesimpulan
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          {_renderForm()}
          <div inline-datepicker data-date="02/25/2022"></div>
          {_renderLoading()}
        </div>

        <div className="w-full lg:w-4/12 px-4 bg-white"></div>
      </div>
    </>
  );
}
