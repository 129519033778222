import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import Home from "views/admin/Home.js";
import Search from "views/admin/Search.js";
import Sentiment from "views/admin/Sentiment.js";
import History from "views/admin/History.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import Check from "views/admin/Check.js";
import HistoryTopic from "views/admin/HistoryTopic";
import Summary from "views/admin/Summary";
import Track from "views/admin/Track";
import Blaclist from "views/admin/Blaclist";
import NewTrack from "views/admin/NewTrack";
import NewTrackNews from "views/admin/NewTrackNews";
import Predict from "views/admin/Predict";
import NewBlacklist from "views/admin/NewBlacklist";
import UpdateTrack from "views/admin/UpdateTrack";
import UpdateNewsTrack from "views/admin/UpdateNewsTrack";
import UpdateBlacklist from "views/admin/UpdateBlacklist";
import NewsReport from "views/admin/NewsReport";
import TrackNews from "views/admin/TrackNews";
import NewsList from "views/admin/NewsList";
import NewWebsite from "views/admin/NewWebsite";
import UpdateWebsite from "views/admin/UpdateWebsite";
import TopNews from "views/admin/TopNews";

export default function Admin() {
  const location = useLocation();

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <HeaderStats showStats={location.pathname === "/admin/dashboard"} />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Home} />
            <Route path="/admin/topic" exact component={HistoryTopic} />
            <Route path="/admin/history" exact component={History} />
            <Route path="/admin/predict" exact component={Predict} />
            <Route path="/admin/summary" exact component={Summary} />
            <Route path="/admin/check" exact component={Check} />
            <Route path="/admin/search" exact component={Search} />
            <Route path="/admin/track" exact component={Track} />
            <Route path="/admin/sentiment" exact component={Sentiment} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route path="/admin/blacklist" exact component={Blaclist} />
            <Route path="/admin/new-blacklist" exact component={NewBlacklist} />
            <Route path="/admin/new-track" exact component={NewTrack} />
            <Route path="/admin/update-track" exact component={UpdateTrack} />
            <Route
              path="/admin/update-news-track"
              exact
              component={UpdateNewsTrack}
            />
            <Route
              path="/admin/update-blacklist"
              exact
              component={UpdateBlacklist}
            />
            <Route path="/admin/news-report" exact component={NewsReport} />
            <Route path="/admin/news-issue" exact component={TrackNews} />
            <Route
              exact
              path="/admin/new-track-news"
              component={NewTrackNews}
            />
            <Route path="/admin/news-list" exact component={NewsList} />
            <Route path="/admin/new-website" exact component={NewWebsite} />
            <Route
              path="/admin/update-website"
              exact
              component={UpdateWebsite}
            />
            <Route
              path="/admin/update-website"
              exact
              component={UpdateNewsTrack}
            />
            <Route path="/admin/top-news" exact component={TopNews} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
