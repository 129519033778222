import React from "react";

// components

export default function CardPageVisits() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Tangggal
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Page name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Bounce rate
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=" w-xs border-t-0  border-l-0 border-r-0 text-xs  p-4 text-left">
                  3/03/2023 13:00
                </td>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-left max-w-xs whitespace-normal">
                Menurut Budiman, kedatangannya hanya untuk mencairkan dan menyatukan kaum nasionalis di Indonesia. Sebaliknya, Prabowo-Ganjar nantinya bisa dibicarakan antara pemilik kepentingan. #PrabowoUntukSemua #PrabowoNeruskePakdhe Prabowo Subianto
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center" title="netral">
                  <span><i className="fas fa-dot-circle text-gray-400 mr-4" ></i> Netral</span>
                </td>
              </tr>
              <tr>
                <td className=" w-xs border-t-0  border-l-0 border-r-0 text-xs  p-4 text-left">
                  3/03/2023 13:00
                </td>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-left max-w-xs whitespace-normal">
                Menurut Budiman, kedatangannya hanya untuk mencairkan dan menyatukan kaum nasionalis di Indonesia. Sebaliknya, Prabowo-Ganjar nantinya bisa dibicarakan antara pemilik kepentingan. #PrabowoUntukSemua #PrabowoNeruskePakdhe Prabowo Subianto
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center" title="netral">
                  <span><i className="fas fa-arrow-up text-emerald-500 mr-4" ></i> Positif</span>
                </td>
              </tr>
              <tr>
                <td className=" w-xs border-t-0  border-l-0 border-r-0 text-xs  p-4 text-left">
                  3/03/2023 13:00
                </td>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-left max-w-xs whitespace-normal">
                Menurut Budiman, kedatangannya hanya untuk mencairkan dan menyatukan kaum nasionalis di Indonesia. Sebaliknya, Prabowo-Ganjar nantinya bisa dibicarakan antara pemilik kepentingan. #PrabowoUntukSemua #PrabowoNeruskePakdhe Prabowo Subianto
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center" title="netral">
                  <span><i className="fas fa-arrow-down text-red-500 mr-4" ></i> Negatif</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
