import React, { useEffect, useState } from "react";
import { checkLogin } from "helper/session";

// components
import CardPieChart from "components/Cards/CardPieChart.js";
import NewsList from "components/Cards/NewsList.js";
import { ENDPOINT } from "constants/endpoints";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function NewsReport() {
  const [loading, setLoading] = useState(false);
  const [issueList, setIssueList] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startText, setStartText] = useState("");
  const [endText, setEndText] = useState("");
  const [issueText, setIssueText] = useState("");
  const [websiteList, setWebsiteList] = useState([]);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState(0);
  const [totalPositive, setTotalPositive] = useState(0);
  const [totalNegative, setTotalNegative] = useState(0);
  const [totalNeutral, setTotalNeutral] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [website, setWebsite] = useState("Semua Website");

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const id = await fetchIssueList();
    await fetchIssue(id);
    setLoading(false);
  };

  const fetchIssueList = async () => {
    const endpoint = `${ENDPOINT.BACKEND}/news-issue`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();
    console.log("isu", res);
    fetchNewsList();

    const defauldId = res.data[0].id;
    console.log("defauldId", defauldId);
    await setSelectedId(defauldId);
    await setIssueList(res.data);
    setIssueText(res?.data[0].attributes.query);

    return defauldId;
  };

  const fetchNewsList = async () => {
    const endpoint = `${ENDPOINT.BACKEND}/news-url`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();

    let data = [{ id: 0, name: "Semua Website" }];
    res.data.map((e) => {
      const d = {
        id: e.id,
        name: e.attributes.name,
      };
      data.push(d);
    });
    setWebsiteList(data);
  };

  const fetchIssue = async (id = 0) => {
    setLoading(true);
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const issueId = id === 0 ? selectedId : id;

    const issueData = issueList.find((e) => e.id == issueId);
    console.log("ISSUE_DATA", issueData);
    if (issueData) {
      setIssueText(issueData.attributes.query);
    }

    const endpoint = `${ENDPOINT.BACKEND}/news-sentiment?id=${issueId}&start=${start}&end=${end}&newsId=${selectedWebsiteId}&isFull=true`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();
    console.log("URL", endpoint);

    let totPositive = 0;
    let totNegative = 0;
    let totNeutral = 0;

    setNewsList(res?.data);

    res.data.map((e) => {
      if (e.attributes.sentiment == "positive") {
        totPositive++;
      } else if (e.attributes.sentiment == "negative") {
        totNegative++;
      } else if (e.attributes.sentiment == "neutral") {
        totNeutral++;
      }
    });
    setTotalPositive(totPositive);
    setTotalNegative(totNegative);
    setTotalNeutral(totNeutral);

    const s = moment(startDate).format("DD/MM/YYYY");
    const e = moment(endDate).format("DD/MM/YYYY");
    setStartText(s);
    setEndText(e);

    setLoading(false);
    return console.log("fetchIssue", res);
  };

  const getTopics = (list) => {
    // All post topics
    const topicCounts = {};
    list.forEach((item) => {
      const topic = item.attributes.topic;
      if (topicCounts[topic]) {
        topicCounts[topic].count++;
      } else {
        topicCounts[topic] = {
          count: 1,
          topic: topic,
        };
      }
    });

    const totalCount = Object.values(topicCounts).reduce(
      (sum, { count }) => sum + count,
      0
    );
    const resultArray = Object.values(topicCounts).map(({ count, topic }) => ({
      count,
      topic,
      percentage: ((count / totalCount) * 100).toFixed(2),
    }));

    // Sort resultArray based on count in descending order
    resultArray.sort((a, b) => b.count - a.count);
    // show topic
    console.log("topicCounts", resultArray);
    return resultArray.filter((e) => e.percentage >= 30);
  };

  const handleOnChange = (event) => {
    setSelectedId(event.target.value);
  };

  const handleOnChangeWebsite = (event) => {
    setSelectedWebsiteId(event.target.value);
  };

  const handleOnCLick = async () => {
    setLoading(true);
    await fetchIssue();
    setLoading(false);
    const data = websiteList.find((e) => e.id == selectedWebsiteId);
    if (data) {
      setWebsite(data.name);
    }
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  const _renderSelection = () => {
    return (
      <>
        <div className="mb-4">
          <label
            for="countries"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Pilih Isu
          </label>
          <select
            onChange={(event) => handleOnChange(event)}
            id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {issueList.map((e) => {
              return <option value={e.id}>{e.attributes.query}</option>;
            })}
          </select>
        </div>

        <div className="mb-4">
          <label
            for="countries"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Pilih Website Berita
          </label>
          <select
            onChange={(event) => handleOnChangeWebsite(event)}
            id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {websiteList.map((e) => {
              return <option value={e.id}>{e.name}</option>;
            })}
          </select>
        </div>
      </>
    );
  };

  const _renderFilter = () => {
    return (
      <div date-rangepicker className="flex items-center justify-center">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            </svg>
          </div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <span className="mx-4 text-gray-500">sampai</span>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            </svg>
          </div>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>
    );
  };

  const _renderTitle = () => {
    if (loading) {
      return _renderLoading();
    }

    const tgl =
      startText === endText ? startText : `${startText} sampai ${endText}`;

    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 		">
          <p className="text-2xl	font-bold ">{issueText}</p>

          <p className="text-sm	 ">di {website}</p>
          <p className="text-sm	font-bold ">{tgl}</p>
        </div>
      </>
    );
  };

  const _renderTop = () => {
    if (loading) {
      return null;
    }

    return (
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5">
        {_renderSelection()}
        {_renderFilter()}
        <button
          onClick={handleOnCLick}
          className="mt-5 bg-lightBlue-500 text-white  font-bold px-3 py-1.5  rounded outline-none focus:outline-none mr-1 mb-1"
          type="button"
        >
          Tampilkan Data
        </button>
      </div>
    );
  };

  const showList = () => {
    if (loading) return null;

    return (
      <div className="justify-self-center">
        <NewsList data={newsList} />
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {_renderTop()}
          {_renderTitle()}
          {showList()}
        </div>
        <div className="w-full xl:w-4/12 px-4">
          {loading ? (
            _renderLoading()
          ) : (
            <CardPieChart
              data={[totalPositive, totalNegative, totalNeutral]}
              title=""
            />
          )}
        </div>
      </div>
      <div className="flex flex-wrap mt-4"></div>
    </>
  );
}
