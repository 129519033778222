import React from "react";

// components

export default function CardFormSearch() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
         sdad
        </div>
      </div>
    </>
  );
}
