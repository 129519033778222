
import { ReactSession } from 'react-client-session';


const checkLogin = () => {
  ReactSession.setStoreType("localStorage");
  
  const token = ReactSession.get("token");

  if(!token){
    return window.location.href = "/auth/login";
  }
}

const logout = async () => {
  ReactSession.setStoreType("localStorage");
  
  await ReactSession.setStoreType("localStorage");
  await ReactSession.set("token", null);
  return window.location.href = "/auth/login";
}

export {
  checkLogin,
  logout
} ;