import React, { useEffect, useState } from "react";
import {ENDPOINT} from 'constants/endpoints'
// components
import CardBlacklistForm from "components/Cards/CardBlacklistForm.js";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
  } from "react-router-dom"

export default function UpdateBlacklist() {

  const [loading, setLoading] = useState(false)
  const [account, setAccount] = useState('')
  const [name, setName] = useState('')

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const id = queryParameters.get("id")
  const endpoint = `${ENDPOINT.BACKEND}/blacklist/${id}`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true)
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    let res = await fetch(endpoint, requestOptions);
    res = await res.json();


    if(res.data){
      console.log('masuk',res.data.attributes)
      const data = res.data.attributes;
      setAccount(data.username)
      setName(data.display_name)
    }
    setLoading(false)
  }

  const handleChangeAccount = (e)=> {
    setAccount(e.target.value)
  }
  const handleChangeName = (e)=> {
    setName(e.target.value)
  }

  const onValidate = () => {
    if(account === ''){
      alert("Akun harus diisi");
      return false
    }
    return true;
  }

  const onSubmit = async () => {
    if(onValidate() && !loading){
        setLoading(true)
        const payload = {
          data: {
            username: account,
            display_name: name,
          },
          id: id
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const endpoint = `${ENDPOINT.BACKEND}/blacklist/update`;
        let res = await fetch(endpoint, requestOptions);
        res = await res.json()
        console.log(res)
        window.location.href = "/admin/blacklist";
        setLoading(false)
        return console.log("res",res)
    }
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <CardBlacklistForm 
            onChangeAccount={handleChangeAccount} 
            onChangeName={handleChangeName}
            onSubmit={onSubmit}
            account={account}
            name={name}
            />
        </div>
      </div>
    </>
  );
}
