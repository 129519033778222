import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { checkLogin } from "helper/session";
import { ENDPOINT } from "constants/endpoints";
// components
import CardPieChart from "components/Cards/CardPieChart.js";
import CardSearchResult from "components/Cards/CardSearchResult.js";
import moment from "moment";

export default function Search(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [pieData, setPieData] = useState([0, 0, 0]);

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const query = queryParameters.get("q");

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchIssue();
  }, []);

  const fetchIssue = async () => {
    setLoading(true);

    const endpoint = `${ENDPOINT.BACKEND}/twitter/check-issue`;
    console.log("LOG", endpoint);

    let res = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: query,
      }),
    });
    res = await res.json();

    let list = [];
    res.data.list.map((e) => {
      const item = {
        date: moment(e.posted_at).format("lll"),
        text: e.raw_text,
        sentiment: e.label,
      };
      list.push(item);
    });
    setData(list);
    setPieData([
      res.data.total_positive,
      res.data.total_negative,
      res.data.total_neutral,
    ]);
    setLoading(false);
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {loading ? (
            _renderLoading()
          ) : (
            <CardSearchResult query={query} data={data} />
          )}
        </div>
        <div className="w-full xl:w-4/12 px-4">
          {loading ? (
            _renderLoading()
          ) : (
            <CardPieChart data={pieData} title="Hasil Sentimen" />
          )}
        </div>
      </div>
    </>
  );
}
