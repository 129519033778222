import React, { useEffect, useState } from "react";
import { ENDPOINT } from "constants/endpoints";
// components
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import CardTrackNewsForm from "components/Cards/CardTrackNewsForm.js";

export default function UpdateNewsTrack() {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [keyword, setKeyword] = useState("");
  const [maxData, setMaxData] = useState("100");
  const [minAlertData, setMinAlertData] = useState("200");
  const [minAlertPercent, setMinAlertPercent] = useState("70");
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const id = queryParameters.get("id");
  const endpoint = `${ENDPOINT.BACKEND}/news-issue/${id}`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    let res = await fetch(endpoint, requestOptions);
    res = await res.json();

    if (res.data) {
      console.log("masuk", res.data.attributes);
      const data = res.data.attributes;

      setTitle(data?.title);
      setKeyword(data.query);
      setMaxData(data?.max_data);
      setMinAlertData(data?.min_data_alert);
      setMinAlertPercent(data?.percent_alert_number);
    }
    setLoading(false);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };
  const handleChangeMaxData = (e) => {
    setMaxData(e.target.value);
  };
  const handleChangeMinData = (e) => {
    setMinAlertData(e.target.value);
  };
  const handleChangeMinPercent = (e) => {
    setMinAlertPercent(e.target.value);
  };

  const onValidate = () => {
    if (!title || !keyword || !maxData || !minAlertData || !minAlertPercent) {
      alert("Data harus diisi");
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    console.log({
      title,
      keyword,
      maxData,
      minAlertData,
      minAlertPercent,
    });

    if (onValidate) {
      const payload = {
        data: {
          query: keyword,
        },
        id: id,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      const endpoint = `${ENDPOINT.BACKEND}/news-issue/update`;
      let res = await fetch(endpoint, requestOptions);
      res = await res.json();

      console.log("res", res);
      return (window.location.href = "/admin/news-issue");
    }
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          {loading ? (
            _renderLoading()
          ) : (
            <CardTrackNewsForm
              onChangeKeyword={handleChangeKeyword}
              onSubmit={onSubmit}
              keyword={keyword}
            />
          )}
        </div>
      </div>
    </>
  );
}
