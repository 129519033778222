import React, { useEffect, useState } from "react";
import { ReactSession } from 'react-client-session';
import {ENDPOINT} from 'constants/endpoints'

export default function Login() {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    checkLogin()
  }, []);

const checkLogin = () => {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  if(token){
    return window.location.href = "/admin/dashboard";
  }
}

  const onChangeUsername = (e)=> {
    setUsername(e.target.value)
  }

  const onChangePassword = (e)=> {
    setPassword(e.target.value)
  }


  const onSubmit = async() => {
    setLoading(true)
    const payload = {
        username: username,
        password: password,
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    const endpoint = `${ENDPOINT.BACKEND}/auth`;
    let res = await fetch(endpoint, requestOptions);
    res = await res.json()
    setLoading(false)

    if(res.status){
      return onLogin();
    }else{
      alert('Username / Password salah')
    }
  }


  const onLogin = async () => {
    await ReactSession.setStoreType("localStorage");
    await ReactSession.set("token", "xxx");
    return window.location.href = "/admin/dashboard";
  }

  const renderForm = () => {
    const isDisabled = !username || !password || loading;
    const btnBg = isDisabled ? 'bg-gray-300 ' : 'bg-lightBlue-500';

    return (
      <>
      <div className="font-bold uppercase font-bold block text-lightBlue-600 text-xl">Media Intelligence Research and Analysis Report</div>
      <div className="m-auto mt-20">
              <div className="text-3xl font-bold ">Selamat datang!</div>
              <div className="text-sm">Silahkan login untuk mengetahui analisis sentimen terkait isu politik saat ini</div>
            </div>
      <form className="mt-12">
        <div className="relative w-full mb-3 ">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Username
          </label>
          <input
            type="text"
            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
            placeholder="Username"
            onChange={(e) => {onChangeUsername(e)}} 
          />
        </div>

        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Password
          </label>
          <input
            type="password"
            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Password"
            onChange={(e) => {onChangePassword(e)}} 
          />
        </div>
        <div>
        </div>
        <div className="text-center mt-6">
          <button
            disabled={isDisabled}
            className={`${btnBg} text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 `}
            type="button"
            onClick={onSubmit}
          >
            Masuk
          </button>
        </div>
      </form>
      </>
    )
  }

  return (
    <div className="flex w-full h-screen" >
        <div className="px-8 py-8" style={{ flex: '30%' }}>
          {renderForm()}
        </div>
        <div className=" " style={{ flex: '70%', backgroundColor: 'rgb(243,243,243)' }}>
          <div
              className="h-full min-h-screen relative "
              style={{
                backgroundImage:
                  "url(" + require("assets/img/bg.png").default + ")",
                  backgroundPositionX: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            >
            <div className="absolute  m-auto" 
            style={{
              left: '20%',
              right: '20%',
              bottom: 50
            }}>
              <div style={{
                textAlign:'center'
              }} className="text-2xl font-bold ">
                Tentukan Sentimen dan Dapatkan Hasil
              </div>
              <div style={{
                textAlign:'center'
              }} className="text-sm">Setiap hasil analinis sentimen yang dicari dapat Anda gunakan untuk menentukan langkah apa yang harus anda ambil agar Anda dapat meraih kemenangan pemilu yang  lebih pasti
              </div>
            </div>
          </div> 
        </div>
  </div>
  )
}

