import React, { useEffect, useState } from "react";
import { checkLogin } from "helper/session";

// components
import CardPieChart from "components/Cards/CardPieChart.js";
import CardTopicList from "components/Cards/CardTopicList.js";
import CardBarChart from "components/Cards/CardBarChart.js";
import PostList from "components/Cards/PostList.js";
import { ENDPOINT } from "constants/endpoints";
import DatePicker from "react-datepicker";
import moment from "moment";

//import Datepicker from "react-tailwindcss-datepicker";

export default function Predict() {
  const [loading, setLoading] = useState(false);
  const [issueList, setIssueList] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startText, setStartText] = useState("");
  const [endText, setEndText] = useState("");
  const [issueText, setIssueText] = useState("");
  const [postList, setPostList] = useState([]);
  const [showPostList, setShowPostList] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showNext, setShowNext] = useState(true);
  const [topicList, setTopicList] = useState([]);
  const [negativeTopic, setNegativeTopic] = useState([]);
  const [positiveTopic, setPositiveTopic] = useState([]);

  useEffect(() => {
    checkLogin();
    // Your code here
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const id = await fetchIssueList();
    await fetchPost(id);
    await fetchIssue(id);
    setLoading(false);
  };

  const fetchIssueList = async () => {
    const endpoint = `${ENDPOINT.BACKEND}/issue/list`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();
    console.log("isu", res);

    const defauldId = res.data[0].id;
    console.log("defauldId", defauldId);
    await setSelectedId(defauldId);
    await setIssueList(res.data);

    return defauldId;
  };

  const fetchIssue = async (id = 0) => {
    setLoading(true);
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const issueId = id === 0 ? selectedId : id;

    const endpoint = `${ENDPOINT.BACKEND}/issue/${issueId}?start=${start}&end=${end}`;
    let res = await fetch(endpoint, {
      method: "GET",
    });
    res = await res.json();
    console.log("endpoint", res);
    setIssueText(res?.data?.title);

    let list = [];
    let pos = [];
    let neg = [];
    let neu = [];
    res.data.list.map((e) => {
      if (start === end) {
        list.push(`${e.hour}:00 WIB`);
      } else {
        list.push(`${e.date}`);
      }
      pos.push(e.total_positive);
      neg.push(e.total_negative);
      neu.push(e.total_neutral);
    });

    const s = moment(res?.data?.start).format("DD MMMM YYYY");
    const e = moment(res?.data?.end).format("DD MMMM YYYY");
    setStartText(s);
    setEndText(e);

    console.log("senti", res);

    /*
      res.data.list.map(e => {
          const item = {
              date: e.attributes.posted_at,
              text: e.attributes.raw_text,
              sentiment: e.attributes.label
          }
          list.push(item);
      })

      setList(list)*/

    //setSelectedId(res.data[0].id)
    setLoading(false);
  };

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const getTopics = (list) => {
    // All post topics
    const topicCounts = {};
    list.forEach((item) => {
      const topic = item.attributes.topic;
      if (topicCounts[topic]) {
        topicCounts[topic].count++;
      } else {
        topicCounts[topic] = {
          count: 1,
          topic: topic,
        };
      }
    });

    const totalCount = Object.values(topicCounts).reduce(
      (sum, { count }) => sum + count,
      0
    );
    const resultArray = Object.values(topicCounts).map(({ count, topic }) => ({
      count,
      topic,
      percentage: ((count / totalCount) * 100).toFixed(2),
    }));

    // Sort resultArray based on count in descending order
    resultArray.sort((a, b) => b.count - a.count);
    // show topic
    console.log("topicCounts", resultArray);
    return resultArray.filter((e) => e.percentage >= 30);
  };

  const fetchPost = async (id = 0) => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const issueId = id === 0 ? selectedId : id;
    const endpoint = `${ENDPOINT.BACKEND}/issue/latest-by-id/${issueId}?start=${start}&end=${end}`;
    let res = await fetch(endpoint, {
      method: "GET",
    });

    res = await res.json();
    console.log("post ", res);

    if (res?.data) {
      setIssueText(res?.data?.query);

      const allTopics = getTopics(res?.data);
      setTopicList(allTopics);

      const positiveData = res?.data.filter(
        (e) => e.attributes.label === "positive"
      );
      const topicPositive = getTopics(positiveData);
      setPositiveTopic(topicPositive);

      const negativeData = res?.data.filter(
        (e) => e.attributes.label === "negative"
      );
      const topicNEgative = getTopics(negativeData);
      setNegativeTopic(topicNEgative);
    }
  };

  const handleOnChange = (event) => {
    setSelectedId(event.target.value);
  };

  const handleOnCLick = async () => {
    setLoading(true);
    await fetchPost();
    await fetchIssue();
    setLoading(false);
  };

  const onPressNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    const l = paginate(postList, 10, nextPage);
    const newList = showPostList.concat(l);
    setShowPostList(newList);

    console.log("nextPage", nextPage);
    console.log("newList", newList);

    if (nextPage >= maxPage) {
      setShowNext(false);
    }
  };

  const _renderLoading = () => {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 items-center		">
          <div className="fa-2x justify-self-center">
            <i className="fas fa-spinner fa-spin text-lightBlue-500"></i>
          </div>
        </div>
      </>
    );
  };

  const _renderSelection = () => {
    return (
      <div className="mb-4">
        <label
          for="countries"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Pilih Isu Sentimen
        </label>
        <select
          onChange={(event) => handleOnChange(event)}
          id="countries"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          {issueList.map((e) => {
            return <option value={e.id}>{e.attributes.title}</option>;
          })}
        </select>
      </div>
    );
  };

  const _renderFilter = () => {
    return (
      <div date-rangepicker className="flex items-center justify-center">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            </svg>
          </div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <span className="mx-4 text-gray-500">sampai</span>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            </svg>
          </div>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>
    );
  };

  const _renderTitle = () => {
    if (loading) {
      return _renderLoading();
    }

    const tgl =
      startText === endText ? startText : `${startText} sampai ${endText}`;

    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5 		">
          <p className="text-2xl	font-bold ">{issueText}</p>
          <p className="text-sm	font-bold ">{tgl}</p>
        </div>
      </>
    );
  };

  const _renderTop = () => {
    if (loading) {
      return null;
    }

    return (
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-5">
        {_renderSelection()}
        {_renderFilter()}
        <button
          onClick={handleOnCLick}
          className="mt-5 bg-lightBlue-500 text-white  font-bold px-3 py-1.5  rounded outline-none focus:outline-none mr-1 mb-1"
          type="button"
        >
          Tampilkan Data
        </button>
      </div>
    );
  };

  const start = moment(startDate).format("YYYY-MM-DD");
  const end = moment(endDate).format("YYYY-MM-DD");

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          {_renderTitle()}
          {_renderTop()}
          {topicList.length > 0 && !loading && (
            <CardTopicList
              list={topicList}
              id={selectedId}
              start={start}
              end={end}
              title="Semua Topik"
              bgColor="bg-purple-500"
              activeColor="bg-purple-200"
            />
          )}
          {topicList.length > 0 && !loading && (
            <CardTopicList
              list={positiveTopic}
              id={selectedId}
              start={start}
              end={end}
              label="positive"
              title="Topik Positif"
              bgColor="bg-emerald-500"
              activeColor="bg-emerald-200"
            />
          )}
          {topicList.length > 0 && !loading && (
            <CardTopicList
              list={negativeTopic}
              id={selectedId}
              start={start}
              end={end}
              label="negative"
              title="Topik Negatif"
              bgColor="bg-red-500"
              activeColor="bg-red-200"
            />
          )}
        </div>
        <div className="w-full xl:w-4/12 px-4"></div>
      </div>
      <div className="flex flex-wrap mt-4"></div>
    </>
  );
}
